.cmedia .showImg {
  display: none;
  background-color: rgba(172, 171, 171, 0.59);
  font-weight: bold;
  color: white;
  cursor: pointer;
}
.cmedia:hover .showImg {
  display: flex;
}

.coverp {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 35%;
  z-index: 0;
  background: #0059ff no-repeat center;
  background-size: 35%;
}
.sold {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  pointer-events: none;
}
.sold img {
  user-select: none;
}
